/** GLOBAL **/
const body = document.querySelector('body');
const headerWrapper = document.querySelector('.header-wrapper');

import Swiper, { Manipulation, Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination, Manipulation]);

let swiperTouchStartX;

const productsSliderSwiper = new Swiper('.products-slider', {
  // Optional parameters
  slidesPerView: 1.25,
  spaceBetween: 20,
  centeredSlides: true,
  loop: true,
  keyboard: {
    enabled: true,
  },
  //breakpoints
  breakpoints: {
    '@0.75': {
      slidesPerView: 2,
    },
    '@1.25': {
      slidesPerView: 3,
    },
    '@1.50': {
      slidesPerView: 4,
    },
    '@2.00': {
      slidesPerView: 5,
    },
    '@2.5': {
      slidesPerView: 6,
    },
  },
  on: {
    init(swiper) {
      const totalSlidesLen = swiper.slides.length;
      swiper.el.querySelector('.swiper-button-prev').addEventListener('click', () => {
        if (swiper.isBeginning) {
          swiper.slideTo(totalSlidesLen - 1);
        } else {
          swiper.slideTo(swiper.realIndex - 1);
        }
      });
      
      swiper.el.querySelector('.swiper-button-next').addEventListener('click', () => {
        if (swiper.isEnd) {
          swiper.slideTo(0);
        } else {
          swiper.slideTo(swiper.realIndex + 1);
        }
      });
    },
    
    touchStart(swiper, e) {
      if (e.type === 'touchstart') {
        swiperTouchStartX = e.touches[0].clientX;
      } else {
        swiperTouchStartX = e.clientX;
      }
    },
    
    touchEnd(swiper, e) {
      const tolerance = 150;
      const totalSlidesLen = swiper.slides.length;
      const diff = (() => {
        if (e.type === 'touchend') {
          return e.changedTouches[0].clientX - swiperTouchStartX;
        } else {
          return e.clientX - swiperTouchStartX;
        }
      })();
      if (swiper.isBeginning && diff >= tolerance) {
        swiper.slideTo(totalSlidesLen - 1);
      } else if (swiper.isEnd && diff <= -tolerance) {
        setTimeout(() => {
          swiper.slideTo(0);
        }, 1);
      }
    },
  },
  
});

/** Carousel **/
const carouselSwiper = new Swiper('.carousel', {
  // Optional parameters
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  centeredSlides: true,
  keyboard: {
    enabled: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/** Product page swiper **/
const productSwiper = new Swiper('.product-swiper', {
  // Optional parameters
  slidesPerView: 1,
  spaceBetween: 20,
  centeredSlides: true,
  loop: true,
  pagination: {
    el: ".swiper-pagination"
  },
  keyboard: {
    enabled: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/** HEADER - LOGO SIZE ON SCROLL **/
const headerLogo = document.querySelector('.header-logo-wrapper');
let ticking = false;

function resizeLogo() {
  if (document.body.scrolltop > 50 || document.documentElement.scrollTop > 50) {
    headerLogo.classList.add('w-[145px]');
  } else {
    headerLogo.classList.remove('w-[145px]');
  }
}

window.onscroll = function() {
  ticking = true;
}

setInterval(() => {
  if (ticking) {
    ticking = false;
    resizeLogo();
  }
},300);

/**  NAVIGATION **/
const nav = headerWrapper.querySelector('.nav');
const toggleMenuBtn = headerWrapper.querySelector('.toggle-menu-button');
const navListWrappers = nav.querySelectorAll('.nav__list-wrapper');
const closeMenuIcon = headerWrapper.querySelector('.close-menu')
const burgerIcon = headerWrapper.querySelector('.burger');

  toggleMenuBtn.addEventListener('click', () => {
    toggleMenu();
  })

function toggleMenu() {
  body.classList.toggle('overflow-hidden');
  nav.classList.toggle('hidden');
  burgerIcon.classList.toggle('hidden');
  closeMenuIcon.classList.toggle('hidden');
}

navListWrappers.forEach(wrapper => {
    wrapper.querySelector('.nav__list-heading').addEventListener('click', (e) => {
      wrapper.querySelector('.nav__list').classList.toggle('hidden');
    })
});

/** ACCORDION **/
const accordions = document.querySelectorAll('.accordion');

accordions.forEach(accordion => {
  const items = accordion.querySelectorAll('.accordion-item, .accordion-item--invisible');
  items.forEach(item => {
    item.querySelector('.accordion-item__title').addEventListener('click', () => {
      if (item.classList.contains('accordion-item--invisible')) {
        item.querySelector('.accordion-item__content').classList.toggle('invisible');
      } else {
        item.classList.toggle('shadow-md');
        item.classList.toggle('md:shadow-lg');
        item.querySelector('.accordion-item__content').classList.toggle('hidden');
      }
      item.querySelector('.accordion-item__title').classList.toggle('after:rotate-180')
    })
  })
})

/** HEADER ACCORDION **/
const headerAccordions = document.querySelectorAll('.header-accordion');

headerAccordions.forEach(accordion => {
  const items = accordion.querySelectorAll('.header-accordion-item, .header-accordion-item--invisible');
  items.forEach(item => {
    item.querySelector('.header-accordion-item__title').addEventListener('click', () => {
      if (item.classList.contains('header-accordion-item--invisible')) {
        item.querySelector('.header-accordion-item__content').classList.toggle('invisible');
      } else {
        item.querySelector('.header-accordion-item__content').classList.toggle('hidden');
      }
      item.querySelector('.header-accordion-item__title').classList.toggle('after:rotate-180')
    })
  })
})

/** VIDEO MODAL **/
const modalVideos = document.querySelectorAll('.modal-videos-js');
modalVideos.forEach(modalVideos => {
  modalVideos.querySelector('.close-modal-button').addEventListener('click', () => {
    modalVideos.querySelector('.video-modal').classList.toggle('hidden');
    modalVideos.querySelector('.video-wrapper').classList.toggle('translate-y-1/3');
    modalVideos.querySelector('.video-wrapper').classList.toggle('opacity-0');
    body.classList.toggle('overflow-hidden');
  })
  modalVideos.querySelectorAll('.videoModal__item').forEach(item => {
    item.addEventListener('click', (e) => {
      body.classList.toggle('overflow-hidden');
      item.closest('.modal-videos-js').querySelector('.video-modal').classList.toggle('hidden');
      item.closest('.modal-videos-js').querySelector('.video-wrapper').classList.toggle('translate-y-1/3');
      item.closest('.modal-videos-js').querySelector('.video-wrapper').classList.toggle('opacity-0');
      item.closest('.modal-videos-js').querySelector('.modal-video').setAttribute('src', item.dataset.videoUrl)
    })
  })
})

/** RETAILER FILTER **/
const retailer = document.querySelector('.retailer-js');
if (typeof retailer !== undefined && retailer !== null) {
  const dropdown = document.getElementById('retailer-select');
  const allRetailers = retailer.querySelectorAll('.retailer-item');

    if (allRetailers.length > 0) {
        updateList(allRetailers);
    }

    dropdown.addEventListener('change', () => {
        updateList(allRetailers);
    })

    function updateList(cardList) {
        cardList.forEach(card => {
            if (card.dataset.location.includes(dropdown.value)) {
                card.classList.remove('hidden');
            } else {
                card.classList.add('hidden');
            }
        })
    }
}

/**  PRODUCTS FILTER **/
const productFilters = document.querySelectorAll('.products-filter-js');
productFilters.forEach(filter => {
  const resetBtn = filter.querySelector('.products-filter-btn');
  // console.log(resetBtn.innerHTML);
  let activeBtn = resetBtn;
  activeBtn.classList.remove('btn-white-alt');
  activeBtn.classList.add('btn-blue');
  const productItems = filter.querySelectorAll('.products-filter-item');

  let hasSlider = false;
  if (filter.classList.contains('products-swiper-slider')) {
    hasSlider = true;
  }

  filter.querySelectorAll('.products-filter-btn').forEach(btn => {
    // Set active category from url location search
    if(window.location.search) {
      const params = new URL(location.href).searchParams;
      const category = params.get('kategori');
      const filterBtn = btn.dataset.categoryBtn;

      if (filterBtn === category) {
        console.log(btn)
        console.log(activeBtn.innerHTML)
        activeBtn.classList.remove('btn-blue');
        activeBtn.classList.add('btn-white-alt');
        btn.classList.remove('btn-white-alt');
        btn.classList.add('btn-blue');
        activeBtn = btn;
        filterProducts(btn.dataset.categoryBtn, resetBtn, productItems, hasSlider);
        if (hasSlider) {
          updateSwiper();
        }
      }
    }
    
    btn.addEventListener('click', () => {
      if (btn === activeBtn) {
        return;
      }
      activeBtn.classList.remove('btn-blue');
      activeBtn.classList.add('btn-white-alt');
      btn.classList.remove('btn-white-alt');
      btn.classList.add('btn-blue');
      activeBtn = btn;
      filterProducts(btn.dataset.categoryBtn, resetBtn, productItems, hasSlider);
      if (hasSlider) {
        updateSwiper();
      }
    })
  })
})

function filterProducts(category, resetBtn, items, hasSlider) {
  if (category === resetBtn.dataset.categoryBtn) {
    showAllItems(items, hasSlider);
    return;
  }
  
  hideAllItems(items, hasSlider);
  
  let c = 0;
  items.forEach((item, i) => {
    if (item.dataset.category === category) {
      item.classList.remove('hidden');
      if (hasSlider) {
        productsSliderSwiper.addSlide(c, item);
      }
      c++;
    }
  })
}

function showAllItems(items, hasSlider) {
  items.forEach((item, i) => {
    if (item.classList.contains('hidden')) {
      item.classList.remove('hidden');
      if (hasSlider) {
        productsSliderSwiper.addSlide(i, item);
      }
    }
  })
}

function hideAllItems(items, hasSlider) {
  items.forEach((item, i) => {
    if (!item.classList.contains('hidden')) {
      item.classList.add('hidden');
    }
  })
  if (hasSlider) {
    productsSliderSwiper.removeAllSlides();
  }
}

function updateSwiper() {
  productsSliderSwiper.update();
}

const tabPanes = document.querySelectorAll('.nav-link');
const tabContent = document.querySelectorAll('.tab-pane');

tabPanes.forEach(pane => {
  pane.addEventListener('click',(e)=>{
    e.preventDefault();
    tabPanes.forEach((p,i)=>{
      p.classList.remove('active');
    })
    let newActiveElementId = pane.getAttribute('data-bs-target');
    pane.classList.add('active');
    tabContent.forEach(c=>{
      c.classList.remove('show')
      if(newActiveElementId.indexOf(c.id) !== -1)
        c.classList.add('show');
    })
  })
})

